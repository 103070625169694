import cn from "classnames"
import React from "react"
import Sound from "react-sound"

import { useSoundContext } from "../SoundProvider/SoundProvider"
import styles from "./index.module.css"

export const SoundButton = () => {
  const { toggleSoundStatus, playStatus } = useSoundContext()

  return (
    <>
      <div
        className={cn(styles.ButtonVolume, {
          [styles.Mute]: playStatus === Sound.status.PAUSED,
        })}
        onClick={toggleSoundStatus}
      />
    </>
  )
}
