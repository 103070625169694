import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { debounce } from "throttle-debounce"

import { useZoomContext } from "~/components/ZoomProvider/ZoomProvider"
import useIsMobile from "~/hooks/useIsMobile"

import styles from "./index.module.css"

const RedButton = () => {
  const isMobile = useIsMobile()
  const root = document.getElementById("root")
  const overlay = useRef(null)
  const { zoom } = useZoomContext()

  const init = () => {
    const diameter = Math.sqrt(Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)) * 3

    overlay.current.style.width = `${diameter}px`
    overlay.current.style.height = `${diameter}px`
    overlay.current.style.top = `-${diameter / 2}px`
    overlay.current.style.left = `-${diameter / 2}px`
  }

  const zoomReset = () => {
    overlay.current.removeEventListener("transitionend", zoomReset)

    root.dataset.state = ""
  }

  const zoomOutEnd = () => {
    overlay.current.removeEventListener("transitionend", zoomOutEnd)
    window.dispatchEvent(new Event("zoom-end"))

    root.dataset.state = "zoom-out-end"

    window.scrollTo(0, document.getElementById("projects").offsetTop * zoom)

    overlay.current.addEventListener("transitionend", zoomReset)
  }

  const zoomOutStart = () => {
    root.dataset.state = "zoom-out-start"
    overlay.current.addEventListener("transitionend", zoomOutEnd)
  }

  const [pulseAnimation, setPulseAnimation] = useState(false)
  const startAnimations = () => {
    setPulseAnimation(true)
    window.timeOut = setTimeout(() => {
      zoomOutStart()
    }, 1000)
  }

  const stopAnimations = () => {
    setPulseAnimation(false)
    clearTimeout(window.timeOut)
  }

  useEffect(() => {
    if (isMobile || isMobile === undefined) return

    init()

    window.addEventListener("resize", debounce(100, init))

    return () => window.removeEventListener("resize", debounce(100, init))
  }, [isMobile])

  return (
    <div
      className={cn(styles.Wrapper, { [styles.PulseAnimation]: pulseAnimation })}
      onMouseEnter={startAnimations}
      onMouseLeave={stopAnimations}
    >
      <div className={styles.OverlayWrapper}>
        <div className={styles.Overlay} ref={overlay} />
      </div>

      <div className={styles.Title}>Bashnya AR</div>
    </div>
  )
}

export default RedButton
