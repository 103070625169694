import React, { createContext, useContext, useEffect } from "react"
import Sound from "react-sound"
import { throttle } from "throttle-debounce"

import { useSetState } from "~/hooks/useSetState"
import { useWindowSize } from "~/hooks/useWindowSize"

import city from "./assets/city.mp3"
import wind from "./assets/wind.mp3"

const SoundContext = createContext({})

export const useSoundContext = () => {
  return useContext(SoundContext)
}

export const SoundProvider = ({ children }) => {
  const { width } = useWindowSize()
  const [state, setState] = useSetState({
    playStatus: Sound.status.STOPPED,
    volumeWind: 0,
    volumeCity: 0,
  })

  const toggleSoundStatus = () => {
    const playStatus =
      state.playStatus === Sound.status.PLAYING ? Sound.status.PAUSED : Sound.status.PLAYING
    setState({ playStatus })
  }

  const playSound = () => {
    setState({ playStatus: Sound.status.PLAYING })

    let start = performance.now()
    const duration = 3000

    requestAnimationFrame(function step(now) {
      let progress = ((now - start) / duration) * 100

      if (progress >= 100) {
        return
      }

      setState({ volumeWind: progress })
      requestAnimationFrame(step)
    })
  }

  const setVolumeByScroll = () => {
    const MAX_VOLUME = 75
    if (window.scrollY > 0) {
      const volume = Math.min(window.scrollY / 7000, 1) * MAX_VOLUME
      setState({ volumeWind: MAX_VOLUME - volume, volumeCity: volume })
    }
  }

  useEffect(() => {
    window.addEventListener("playSound", playSound)
    window.addEventListener("scroll", throttle(500, setVolumeByScroll))
  }, [])

  const data = {
    ...state,
    toggleSoundStatus,
  }

  return (
    <SoundContext.Provider value={data}>
      {width >= 768 && (
        <React.Fragment>
          <Sound url={wind} autoLoad playStatus={state.playStatus} volume={state.volumeWind} loop />
          <Sound url={city} autoLoad playStatus={state.playStatus} volume={state.volumeCity} loop />
        </React.Fragment>
      )}
      {children}
    </SoundContext.Provider>
  )
}
