import { useEffect, useState } from "react"

export const useWindowSize = () => {
  if (typeof window !== "object") {
    return { width: undefined, height: undefined }
  }

  const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const [size, setSize] = useState(getSize())

  useEffect(() => {
    const onResize = () => {
      setSize(getSize())
    }

    window.addEventListener("resize", onResize, false)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return size
}
