import React from "react"
import { InView } from "react-intersection-observer"

import { Border } from "~/components/Helpers/Border/Border"
import { Title } from "~/components/Helpers/Title/Title"

import { Heading } from "../Heading/Heading"
import styles from "./index.module.css"

export const ContentMobile = ({ items }) => (
  <div className={styles.Wrapper}>
    <Title as="h2" type="text" className={styles.Title}>
      История башни
    </Title>

    <div>
      {items.map(({ fact, photo, video }, i) => (
        <InView key={i} threshold="0.7" triggerOnce>
          {({ inView, ref }) => (
            <div className={styles.Item} ref={ref} data-state={inView ? "visible" : ""}>
              <Heading className={styles.Heading} heading={fact.heading} />

              {photo && (
                <div className={styles.Photo}>
                  {photo.map((item) => (
                    <Border key={item}>
                      <img src={item} />
                    </Border>
                  ))}
                </div>
              )}

              {video && (
                <div key={photo} className={styles.Photo}>
                  <Border>
                    <div className={styles.Video}>
                      <iframe
                        src={video}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </Border>
                </div>
              )}

              <div
                className={styles.Description}
                dangerouslySetInnerHTML={{ __html: fact.description }}
              />
            </div>
          )}
        </InView>
      ))}
    </div>
  </div>
)
