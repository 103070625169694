import cn from "classnames"
import * as NProgress from "nprogress"
import React, { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"

import { ButtonDesktop } from "../Button/ButtonDesktop"
import { ButtonMobile } from "../Button/ButtonMobile"
import styles from "./index.module.css"

export const Preloader = () => {
  const [showButton, setShowButton] = useState(false)
  const [isReady, setReady] = useState(false)
  const onClick = () => {
    NProgress.done()
    setShowButton(false)
    setReady(true)
    document.body.style.position = ""
    document.body.style.width = ""
    window.dispatchEvent(new Event("playSound"))
  }

  useEffect(() => {
    NProgress.start()

    document.body.style.position = "fixed"
    document.body.style.width = "100%"

    window.addEventListener("glProgress", (e) => {
      if (e.detail && e.detail.textures === true) {
        NProgress.set(0.75)
      }
      if (e.detail && e.detail.tower === true) {
        NProgress.set(0.99)
        setShowButton(true)
      }
    })
  }, [])

  return (
    <CSSTransition
      in={!isReady}
      unmountOnExit
      timeout={2000}
      classNames={{ exitActive: styles.WrapperExitActive }}
    >
      <div className={styles.Wrapper}>
        {/* <div className={styles.Title}>
          <Title as="span" type="numbers">
            70
          </Title>
          <Title as="span" type="numbers">
            %
          </Title>
        </div> */}

        <div className={styles.Curtains}>
          <div className={styles.Curtain} />
          <div className={styles.Curtain}>
            <div className={cn(styles.TextContainer, "container")}>
              <div className={styles.Text}>
                Для большего погружения <br />
                рекомендуем включить звук
              </div>
            </div>
            <div className={cn(styles.TextContainer, "container")}>
              <div className={styles.ButtonWrapper}>
                <div className={styles.ButtonDesktop} style={{ opacity: showButton ? "1" : "0" }}>
                  <ButtonDesktop onClick={onClick} title={"Продолжить"} />
                </div>
                <div className={styles.ButtonMobile} style={{ opacity: showButton ? "1" : "0" }}>
                  <ButtonMobile onClick={onClick} title={"Продолжить"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
