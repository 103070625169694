import cn from "classnames"
import React from "react"

import styles from "./index.module.css"

export const Title = ({ children, as, type = "text", className, ...props }) => {
  const FullClassName = cn(className, {
    [styles.Text]: type === "text",
    [styles.Numbers]: type === "numbers",
  })

  const Component = as || "div"

  return (
    <Component
      className={FullClassName}
      {...props}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}
