import React from "react"

import styles from "./index.module.css"

export const Share = () => (
  <div className={styles.Wrapper}>
    <div className={styles.Title}>Поделиться</div>

    <div className={styles.Inner}>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="http://vk.com/share.php?url=https://etower.ru"
        className={styles.Link}
      >
        VK
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="http://www.facebook.com/sharer.php?s=100&p[url]=https://etower.ru"
        className={styles.Link}
      >
        FB
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="http://twitter.com/share?text=Проект digital-агентства BeaversBrothers, в котором мы воссоздали в виртуальном пространстве взорванный символ города – телевизионную башню.&url=https://etower.ru"
        className={styles.Link}
      >
        TW
      </a>
    </div>
  </div>
)
