import cn from "classnames"
import React, { useEffect, useState } from "react"

import { Arrow, Pagination, SliderWrapper, Slides } from "~/components/SliderWrapper/SliderWrapper"
import useIsMobile from "~/hooks/useIsMobile"

import img_1_xs from "./assets/img_01_xs.jpg"
import img_1 from "./assets/img_01.jpg"
import img_2_xs from "./assets/img_02_xs.jpg"
import img_2 from "./assets/img_02.jpg"
import img_3_xs from "./assets/img_03_xs.jpg"
import img_3 from "./assets/img_03.jpg"
import img_4_xs from "./assets/img_04_xs.jpg"
import img_4 from "./assets/img_04.jpg"
import img_5_xs from "./assets/img_05_xs.jpg"
import img_5 from "./assets/img_05.jpg"
import img_6_xs from "./assets/img_06_xs.jpg"
import img_6 from "./assets/img_06.jpg"
import { Caption } from "./components/Caption/Caption"
import styles from "./index.module.css"

const slideItems = [
  {
    img: img_1,
    img_xs: img_1_xs,
    caption: {
      title: "Проект 1",
      description: "Екатеринбургская телебашня",
    },
  },
  {
    img: img_4,
    img_xs: img_4_xs,
    caption: {
      title: "Проект 2",
      description: "Башня, которой не было",
    },
  },
  {
    img: img_2,
    img_xs: img_2_xs,
    caption: {
      title: "Проект 3",
      description: "Бионический храм",
    },
  },
  {
    img: img_3,
    img_xs: img_3_xs,
    caption: {
      title: "Проект 4",
      description: "Миллион ладоней — Башня Человечества",
    },
  },
  {
    img: img_5,
    img_xs: img_5_xs,
    caption: {
      title: "Проект 5",
      description: "Одуванчик",
    },
  },
  {
    img: img_6,
    img_xs: img_6_xs,
    caption: {
      title: "Проект 6",
      description: "Один из ранних проектов",
    },
  },
]

export const Slider = () => {
  const isMobile = useIsMobile()
  const [zIndex, setZindex] = useState(false)

  const setIndex = () => {
    setZindex(true)
  }

  useEffect(() => {
    window.addEventListener("zoom-end", setIndex)
    return () => {
      window.removeEventListener("zoom-end")
    }
  }, [])

  return (
    <SliderWrapper className={styles.Wrapper} params={{ effect: "fade" }}>
      <Slides slides={slideItems}>
        {({ img, img_xs, caption }) => (
          <div
            className={styles.Item}
            style={{ backgroundImage: `url(${isMobile ? img_xs : img})` }}
          >
            <Caption caption={caption} />
          </div>
        )}
      </Slides>

      <div className={styles.Bottom}>
        <Pagination className={styles.Pagination} />
      </div>
      <Arrow
        className={cn(styles.ArrowNext, { [styles.ArrowZindexUp]: zIndex })}
        direction="next"
      />
    </SliderWrapper>
  )
}
