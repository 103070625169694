import cn from "classnames"
import React, { useEffect, useRef } from "react"
import { throttle } from "throttle-debounce"

import { Title } from "~/components/Helpers/Title/Title"

import { useZoomContext } from "../../../../../ZoomProvider/ZoomProvider"
import styles from "./index.module.css"

export const Heading = ({ heading, className }) => {
  const ref = useRef(null)
  const { zoom } = useZoomContext()

  const halfHeight = window.innerHeight / 2

  const fillText = () => {
    const position = halfHeight - ref.current.getBoundingClientRect().top * zoom
    ref.current.style.setProperty("--gradient-title", position + "px")
  }

  useEffect(() => {
    window.addEventListener("scroll", throttle(50, fillText))
  }, [])

  return (
    <div className={cn(styles.Wrapper, className)}>
      <div className={styles.WrapStroke} ref={ref}>
        <Title as="h3" type="numbers" className={styles.Text}>
          {heading}
        </Title>
      </div>
    </div>
  )
}
