import React from "react"

import imgTowerMobile from "./assets/tower-mobile.png"
import { Boom } from "./components/Boom/Boom"
import { Hero } from "./components/Hero/Hero"
import { History } from "./components/History/History"
import styles from "./index.module.css"

export const TowerWrapper = () => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Inner}>
        <div className={styles.Tower}>
          <picture>
            <source media="(max-width: 768px)" srcSet={imgTowerMobile} />
            <img src="" alt="" />
          </picture>
        </div>
        <div className={styles.ComponentsWrap}>
          <Hero />
          <History />
          <Boom />
        </div>
      </div>
    </div>
  )
}
