import React, { createContext, useContext, useEffect } from "react"

import { useWindowSize } from "~/hooks/useWindowSize"

const ZoomContext = createContext({ zoom: 1 })

export const useZoomContext = () => {
  return useContext(ZoomContext)
}

export const ZoomProvider = ({ children }) => {
  const { width } = useWindowSize()

  const zoom = width < 1920 && width >= 768 ? width / 1920 : 1

  useEffect(() => {
    document.body.style.zoom = zoom.toFixed(4)
  }, [width])

  const value = { zoom }

  return React.createElement(ZoomContext.Provider, { value }, children)
}
