import React from "react"

import { Border } from "~/components/Helpers/Border/Border"
import { Title } from "~/components/Helpers/Title/Title"

import { Heading } from "../Heading/Heading"
import styles from "./index.module.css"

export const ContentDesktop = ({ items }) => (
  <div className={styles.Wrapper}>
    <div className={styles.Unifier}>
      <Title as="h2" type="text" className={styles.Title}>
        История башни
      </Title>

      <div className={styles.Photos}>
        {items.map(({ photo, video }) => {
          if (photo) {
            return (
              <div key={photo} className={styles.Photo}>
                {photo.map((item) => (
                  <Border key={item}>
                    <img src={item} />
                  </Border>
                ))}
              </div>
            )
          }

          if (video) {
            return (
              <div key={video} className={styles.Photo}>
                <Border>
                  <div className={styles.Video}>
                    <iframe
                      src={video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </Border>
              </div>
            )
          }
        })}
      </div>
    </div>

    <div className={styles.Facts}>
      {items.map(({ fact }, i) => (
        <div className={styles.Fact} key={i}>
          <Heading heading={fact.heading} />
          <div
            className={styles.Description}
            dangerouslySetInnerHTML={{ __html: fact.description }}
          />
        </div>
      ))}
    </div>
  </div>
)
