import React from "react"

import img_1__1 from "./assets/img_1__1.jpg"
import img_1__2 from "./assets/img_1__2.jpg"
import img_2 from "./assets/img_2.jpg"
import img_3 from "./assets/img_3.jpg"
import img_4__1 from "./assets/img_4__1.jpg"
import img_4__2 from "./assets/img_4__2.jpg"
import img_5 from "./assets/img_5.jpg"
// import img_6 from "./assets/img_6.jpg"
import img_7 from "./assets/img_7.jpg"
import img_8__1 from "./assets/img_8__1.jpg"
import img_8__2 from "./assets/img_8__2.jpg"
import img_9__1 from "./assets/img_9__1.jpg"
import img_9__2 from "./assets/img_9__2.jpg"
import picHeight from "./assets/picHeight.jpg"
import picallin from "./assets/picTallin.jpg"
import picVilnus from "./assets/picVilnus.jpg"
import { ContentDesktop } from "./components/ContentDesktop/ContentDesktop"
import { ContentMobile } from "./components/ContentMobile/ContentMobile"
import styles from "./index.module.css"

const items = [
  {
    fact: {
      heading: "1983",
      description: `Башню начали строить в&nbsp;1983&nbsp;году. Её&nbsp;назначение&nbsp;&mdash; передача теле- и&nbsp;радиосигнала. Башни по&nbsp;такому&nbsp;же проекту стоят в&nbsp;<span class='link'>Таллине<img src='${picallin}' alt='' /></span> <br/>и&nbsp;<span class='link'>Вильнюсе<img src='${picVilnus}' alt='' /></span>.`,
    },
    photo: [img_1__1, img_1__2],
  },
  {
    fact: {
      heading: "239,7&nbsp;<span>M</span>",
      description: `<p>Высота построенной части башни 239,7&nbsp;м. Для&nbsp;сравнения: это чуть меньше половины Останкинской башни, но&nbsp;больше небоскреба &laquo;Высоцкий&raquo;&nbsp;&mdash; 188&nbsp;м.</p> <p>По&nbsp;проекту на&nbsp;башню, с&nbsp;помощью вертолёта, должны были установить металлическую антенну высотой 141&nbsp;м. Тогда&nbsp;бы высота достигла <span class='link'>361&nbsp;метра <img src=${picHeight} alt=''/></span>.</p>`,
    },
    photo: [img_2],
  },
  {
    fact: {
      heading: "188&nbsp;<span>M</span>",
      description:
        "На&nbsp;высоте 188 метров должен был разместиться ресторан на&nbsp;вращающейся платформе, как ресторан &laquo;Седьмое небо&raquo; в&nbsp;московском&nbsp;Останкино.",
    },
    photo: [img_3],
  },
  {
    fact: {
      heading: "7500&nbsp;<span>T</span>",
      description:
        " <p>Под основанием башни вбито 2400&nbsp;свай, чтобы грунт выдержал 7500 тонн бетона.</p> <p>Толщина стен от&nbsp;50&nbsp;сантиметров у&nbsp;основания до&nbsp;30&nbsp;сантиметров наверху. Стены из&nbsp;высокопрочного бетона М400. Из&nbsp;такого бетона строят военные бункеры.</p> ",
    },
    photo: [img_4__1, img_4__2],
  },
  {
    fact: {
      heading: "1990",
      description:
        "В&nbsp;1990 году финансирование прекратилось и&nbsp;строительство башни заморозили.",
    },
    photo: [img_5],
  },
  {
    fact: {
      heading: "2003",
      description:
        "За&nbsp;28&nbsp;лет на&nbsp;башне побывали сотни людей. Особенно просто было подняться на&nbsp;неё в&nbsp;90-е, когда Башню не&nbsp;охраняли. Люди встречали наверху рассвет и&nbsp;пели песни. По&nbsp;официальным данным с&nbsp;башни <span class='mark'>упали 11&nbsp;человек</span>. По&nbsp;неофициальным&nbsp;&mdash; несколько десятков. Последний смертельный случай зарегистрирован в&nbsp;2003&nbsp;году.",
    },
    video: "https://www.youtube.com/embed/JYUEZPjP_uc?controls=0",
    // photo: [img_6],
  },
  {
    fact: {
      heading: "2013",
      description:
        "В&nbsp;2013 году Свердловская область выкупила недостроенную телебашню у&nbsp;ФГУП &laquo;Российская телевизионная и&nbsp;радиовещательная сеть&raquo; за&nbsp;500 млн рублей.",
    },
    photo: [img_7],
  },
  {
    fact: {
      heading: "2017",
      description:
        "В&nbsp;2017 губернатор Свердловской области Куйвашев&nbsp;Е.&nbsp;В. принял решение о&nbsp;сносе&nbsp;телебашни.",
    },
    photo: [img_8__1, img_8__2],
  },
  {
    fact: {
      heading: "2018",
      description:
        "23&nbsp;марта в&nbsp;04:45 утра 4&nbsp;активистов cумели залезть на&nbsp;вершину (впоследствии один спрыгнул с&nbsp;парашютом). Там они вывесили флаг России в&nbsp;знак защиты башни и&nbsp;планировали сидеть там до&nbsp;конца. Трое парней просидели наверху 9&nbsp;часов и&nbsp;вынуждены были спуститься из-за холода, усталости и&nbsp;отсутствия еды. В&nbsp;полиции всем выписали штрафы в&nbsp;500 рублей за&nbsp;мелкое&nbsp;хулиганство.",
    },
    photo: [img_9__1, img_9__2],
  },
]

export const History = () => (
  <div className={styles.Wrapper} id="history">
    <div className="container">
      <ContentMobile items={items} />
      <ContentDesktop items={items} />
    </div>
  </div>
)
