import cn from "classnames"
import React from "react"

import styles from "./index.module.css"

export const Arrow = React.forwardRef(({ className, isCursorArrow, direction }, ref) => {
  if (isCursorArrow)
    return (
      <div
        className={cn(className, {
          [styles.CursorArrowNext]: direction === "next",
          [styles.CursorArrowPrev]: direction === "prev",
        })}
        ref={ref}
      />
    )

  return (
    <div
      className={cn(className, {
        [styles.Next]: direction === "next",
        [styles.Prev]: direction === "prev",
      })}
      ref={ref}
    >
      <div className={styles.Stroke} />

      <div className={styles.FillWrap}>
        <div className={styles.Fill} />
      </div>
    </div>
  )
})
