import React from "react"

import styles from "./index.module.css"

const itemsMenu = [
  { title: "История", href: "#history" },
  { title: "Голос народа", href: "#voices" },
  { title: "Проекты", href: "#projects" },
  // { title: "Открытка", href: "#postcard" },
  { title: "Приложение", href: "#mobile-app" },
]

export const Dropdown = ({ toggleDropdown, scrollTo }) => {
  const clickHandler = (e) => {
    toggleDropdown()
    scrollTo(e)
  }

  return (
    <div className={styles.Wrapper}>
      <div className="container grid grid--col-12">
        <ol className={styles.List}>
          {itemsMenu.map(({ title, href }) => (
            <li key={title} className={styles.Item}>
              <a href={href} className={styles.Link} onClick={clickHandler}>
                {title}
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}
