import React from "react"

import { Title } from "~/components/Helpers/Title/Title"
import { useWindowScroll } from "~/hooks/useWindowScroll"

import styles from "./index.module.css"

export const Boom = () => {
  const { scrollY } = useWindowScroll()

  let opacity = 0
  if (scrollY > 7300) {
    opacity = 1 - (7500 - scrollY) / (7500 - 7300)
    if (opacity > 1) {
      opacity = 1
    }
  }

  return (
    <div id="town-boom" className={styles.Wrapper}>
      <div className="container grid grid--col-12">
        <Title type="numbers" className={styles.Date}>
          24.03.2018
        </Title>
        <Title as="h2" type="text" className={styles.Title}>
          Башню взорвали
        </Title>
      </div>
    </div>
  )
}
