import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"

import { Title } from "~/components/Helpers/Title/Title/"
import useIsTablet from "~/hooks/useIsTablet"

import imgAppStore from "../MobileApp/assets/logo-app-store.svg"
import imgPlayMarket from "../MobileApp/assets/logo-play-market.svg"
import RedButton from "./components/RedButton/RedButton"
import { Slider } from "./components/Slider/Slider"
import styles from "./index.module.css"

export const Future = () => {
  const close = useRef(null)
  const isTablet = useIsTablet()
  const [visibleMask, setVisibleMask] = useState(false)
  const [showContent, setShowContent] = useState(false)

  const setMask = () => {
    setVisibleMask(true)
    document.body.style.overflow = "hidden"
  }

  const closeMask = () => {
    setVisibleMask(false)
    document.body.style.overflow = "unset"
  }

  useEffect(() => {
    window.addEventListener("zoom-end", setMask)
    return () => {
      window.removeEventListener("zoom-end")
    }
  }, [])

  const marquee = useRef(null)
  const animationTime = 25000

  useEffect(() => {
    let start = performance.now()

    requestAnimationFrame(function step(now) {
      let progress = (now - start) / animationTime

      if (progress >= 1) {
        progress = 0
        start = now
      }

      if (!marquee.current) return

      marquee.current.style.transform = `translateX(-${(100 * progress) / 2}%)`
      requestAnimationFrame(step)
    })
  }, [showContent])

  return (
    <div className={styles.Wrapper} id="projects">
      <div className={styles.Intro}>
        <Title as="h2" type="text" className={styles.Title}>
          Какой могла стать башня
        </Title>

        <div className={styles.Description}>
          Взорвать башню решили не&nbsp;сразу. Сначала ее&nbsp;хотели достроить. Превратить
          в&nbsp;развлекательный или научный центр, арт-галерею или гигантский храм. Даже провели
          конкурс, на&nbsp;котором было представлено много интересных проектов. Ни&nbsp;один
          из&nbsp;них не&nbsp;был реализован. Официальная версия&nbsp;&mdash; или невозможно, или
          невероятно дорого.
        </div>
      </div>

      {!isTablet && <RedButton />}
      <CSSTransition
        in={visibleMask}
        unmountOnExit
        timeout={1000}
        classNames={{
          exit: styles.MaskExitActive,
        }}
        onEntering={() => setShowContent(true)}
        onExited={() => setShowContent(false)}
      >
        <div className={cn(styles.Mask, { [styles.MaskActive]: showContent })}>
          <div className={styles.MaskWrapper} id="mobile-app">
            <div className="container grid grid--col-12">
              <div className={styles.Hero}>
                <Title as="h2" type="text" className={styles.MaskTitle}>
                  Bashnya&nbsp;AR
                </Title>

                <div className={styles.MaskDescription}>
                  Посмотри, какой башня могла&nbsp;бы стать, если&nbsp;бы ее&nbsp;достроили.
                  Поднимись на&nbsp;самый верх. Посмотри изнутри. Размести ее&nbsp;в&nbsp;городе.
                  Представь, что взрыва никогда не&nbsp;было.
                </div>

                <div className={styles.Stores}>
                  <a
                    href={`https://play.google.com/store/apps/details?id=ru.dabb.bashnyaar`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.StoresLink}
                  >
                    <img src={imgPlayMarket} />
                  </a>
                  <a
                    href={`https://apps.apple.com/us/app/bashnya-ar/id1485237320?l=ru&ls=1`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.StoresLink}
                  >
                    <img src={imgAppStore} />
                  </a>
                </div>
              </div>

              <div className={styles.InfiniteLine} ref={marquee}>
                <Title type="text" className={styles.InfiniteText}>
                  Впиши башню в&nbsp;городское пространство&nbsp;
                </Title>
                <Title type="text" className={styles.InfiniteText}>
                  Впиши башню в&nbsp;городское пространство
                </Title>
              </div>
            </div>
          </div>
          <div ref={close} onClick={closeMask} className={styles.MaskClose} />
        </div>
      </CSSTransition>
      <Slider />
    </div>
  )
}
