import { useEffect, useState } from "react"
import { debounce } from "throttle-debounce"

const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(undefined)

  const handleWindowWidth = () => {
    const widthWindow = window.innerWidth

    if (widthWindow < 1366) {
      setIsTablet(true)
      return
    }

    setIsTablet(false)
  }

  useEffect(() => {
    handleWindowWidth()
    window.addEventListener("resize", debounce(100, handleWindowWidth))

    return () => window.removeEventListener("resize", debounce(100, handleWindowWidth))
  }, [])

  return isTablet
}

export default useIsTablet
