import React from "react"

import { CircularText } from "../CircularText/CircularText"
import { Title } from "../Helpers/Title/Title"
import imgCircularText from "./assets/circular-text.svg"
import { ReactComponent as Fb } from "./assets/fb.svg"
import { ReactComponent as Inst } from "./assets/inst.svg"
import { ReactComponent as Tw } from "./assets/tw.svg"
import styles from "./index.module.css"

export const Footer = () => (
  <div className={styles.Wrapper}>
    <div className="container grid grid--col-12">
      <Title as="h2" type="text" className={styles.Title}>
        Расскажи &lt;br /&gt; свою историю
      </Title>

      <div className={styles.CircularText}>
        <CircularText src={imgCircularText} />
      </div>

      <div className={styles.Hello}>
        Привет, мы&nbsp;&mdash; Beavers Brothers. Мы&nbsp;создали этот проект, потому что любим наш
        город и&nbsp;нам нравилась башня. Как&nbsp;факт, как&nbsp;идея, как&nbsp;интересный объект,
        который каждый наполнял своим смыслом.
      </div>

      <div className={styles.Share}>
        Расскажи свою историю о&nbsp;башне в&nbsp;любой социальной сети. Отметь хэштегом{" "}
        <span className="mark">#imissyoubashnya #bashnyaseeu #bashnyaremember</span>. Лучшие истории
        мы&nbsp;опубликуем на&nbsp;этом сайте. Авторам&nbsp;&mdash; подарим что-то хорошее.
      </div>

      <div className={styles.SocialWrapper}>
        <div className={styles.SocialInner}>
          <div className={styles.SocialTitle}>Подпишись на нас в соцсетях</div>
          <hr className={styles.Hr} />
        </div>

        <div className={styles.Social}>
          <a
            href="https://www.facebook.com/BeaversBrothers"
            rel="noopener noreferrer"
            target="_blank"
            className={styles.SocialLink}
          >
            <Fb />
          </a>
          <a
            href="https://www.instagram.com/beaversbrothers/"
            rel="noopener noreferrer"
            target="_blank"
            className={styles.SocialLink}
          >
            <Inst />
          </a>
          <a
            href="https://twitter.com/BeaversBrothers"
            rel="noopener noreferrer"
            target="_blank"
            className={styles.SocialLink}
          >
            <Tw />
          </a>
        </div>
      </div>
    </div>
  </div>
)
