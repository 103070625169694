import cn from "classnames"
import React from "react"

import styles from "./index.module.css"

export const Link = ({ children, as, type, className, ...props }) => {
  const FullClassName = cn(className, {
    [styles.White]: type === "white",
    [styles.Red]: type === "red",
  })

  const Component = as || "a"

  return (
    <Component
      className={FullClassName}
      {...props}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}
