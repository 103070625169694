import { useEffect, useState } from "react"
import { debounce } from "throttle-debounce"

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(undefined)

  const handleWindowWidth = () => {
    const widthWindow = window.innerWidth

    if (widthWindow <= 767) {
      setIsMobile(true)
      return
    }

    setIsMobile(false)
  }

  useEffect(() => {
    handleWindowWidth()
    window.addEventListener("resize", debounce(100, handleWindowWidth))

    return () => window.removeEventListener("resize", debounce(100, handleWindowWidth))
  }, [])

  return isMobile
}

export default useIsMobile
