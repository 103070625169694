import React, { useEffect, useState } from "react"
import { InView } from "react-intersection-observer"
import { CSSTransition } from "react-transition-group"

import { CircularText } from "~/components/CircularText/CircularText"
import { Title } from "~/components/Helpers/Title/Title"

import imgCircularText from "./assets/circular-text.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className={styles.Wrapper}>
    <div className="container">
      <div className={styles.Inner}>
        <Title as="h2" type="text" className={styles.Title}>
          Башня, &lt;br /&gt; которой не&nbsp;было
        </Title>

        <div className={styles.CircularText}>
          <CircularText src={imgCircularText} />
        </div>

        <InView triggerOnce>
          {({ inView, ref }) => (
            <div className={styles.Coordinates} ref={ref}>
              <AnimatedNumber coordinates={["56.824424", "60.608624"]} inView={inView} />
            </div>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div className={styles.DescriptionLeft} ref={ref} data-state={inView ? "visible" : ""}>
              <span className="mark">32&nbsp;года</span> у&nbsp;Екатеринбурга была своя наивысшая
              точка. Своя&nbsp;вертикаль. Свой&nbsp;символ. Железобетонный знак&nbsp;&mdash;
              великого прошлого, прекрасного будущего, чего угодно еще.
            </div>
          )}
        </InView>

        <InView triggerOnce>
          {({ inView, ref }) => (
            <div className={styles.DescriptionRight} ref={ref} data-state={inView ? "visible" : ""}>
              В&nbsp;марте 2018 <span className="mark">башню взорвали</span>. Не&nbsp;стало
              ни&nbsp;вертикали, ни&nbsp;знака, ни&nbsp;символа. Мы&nbsp;сделали этот проект для
              того, чтобы показать, какой башня могла&nbsp;бы стать.
            </div>
          )}
        </InView>
      </div>
    </div>
  </div>
)

const AnimatedNumber = ({ coordinates, inView }) => {
  let index = 0

  const characters = coordinates.map((coordinate) =>
    coordinate.split("").map((character) => {
      index += 1
      return { index, character }
    }),
  )

  return characters.map((c, i) => (
    <div className={styles.Numbers} key={i}>
      {c.map(({ character, index }, j) => (
        <Letter character={character} index={index} key={j} inView={inView} />
      ))}
    </div>
  ))
}

const getRandomIntiger = () => Math.floor(Math.random() * 10)

const Letter = ({ character, index, inView }) => {
  if (isNaN(character)) {
    return character
  }

  const [string, setString] = useState(getRandomIntiger)

  useEffect(() => {
    if (!inView) {
      return
    }

    const nIntervId = setInterval(() => setString(getRandomIntiger()), 100)

    setTimeout(() => {
      clearInterval(nIntervId)
      setString(character)
    }, 200 * index)
  }, [inView])

  return (
    <CSSTransition
      in={inView}
      timeout={40 * index}
      classNames={{
        enter: styles.NumberEnter,
      }}
    >
      <span>{string}</span>
    </CSSTransition>
  )
}
