import cn from "classnames"
import React, { createContext, useContext, useEffect, useRef } from "react"
import {
  EffectFade,
  Navigation,
  Pagination as SwiperPagination,
  Swiper,
  Thumbs as SwiperThumbs,
} from "swiper/js/swiper.esm.js"

import { useSetState } from "~/hooks/useSetState"

import { Arrow as ArrowContainer } from "./components/Arrow/Arrow"
import styles from "./index.module.css"

const SliderContext = createContext({})

const useSliderEl = (el) => {
  const { setEl } = useContext(SliderContext)
  useEffect(() => {
    setEl(el)
  }, [])
}

export const Pagination = ({ className }) => {
  const paginationEl = useRef(null)
  useSliderEl({ paginationEl })

  return <div className={cn(className, styles.Pagination)} ref={paginationEl} />
}

export const Arrow = ({ className, isCursorArrow, direction = "next" }) => {
  const nextEl = useRef(null)
  const prevEl = useRef(null)
  // TODO: отрефакторить условия
  if (direction === "next") {
    useSliderEl({ nextEl })
  }
  if (direction === "prev") {
    useSliderEl({ prevEl })
  }

  return (
    <ArrowContainer
      isCursorArrow={isCursorArrow}
      className={className}
      direction={direction}
      ref={direction === "next" ? nextEl : prevEl}
    />
  )
}

const Slide = ({ children, className, as, ...props }) => {
  const Component = as || "div"

  return (
    <Component className={cn(className, styles.Slide)} {...props}>
      {children}
    </Component>
  )
}

const SlidesWrapper = React.forwardRef(({ className, slideClassName, slides, children }, ref) => (
  <div className={cn(styles.Container, className)} ref={ref}>
    <div className={styles.Wrapper}>
      {slides.map((slide, i) => (
        <Slide key={i} className={slideClassName}>
          {children(slide)}
        </Slide>
      ))}
    </div>
  </div>
))

export const Slides = ({ slides, children, className, slideClassName }) => {
  const sliderEl = useRef(null)
  useSliderEl({ sliderEl })

  return (
    <SlidesWrapper
      className={className}
      slideClassName={slideClassName}
      slides={slides}
      ref={sliderEl}
    >
      {children}
    </SlidesWrapper>
  )
}

export const Thumbs = ({ className, slides, children, slideClassName }) => {
  const thumbsEl = useRef(null)
  useSliderEl({ thumbsEl })

  return (
    <SlidesWrapper
      className={className}
      slideClassName={slideClassName}
      slides={slides}
      ref={thumbsEl}
    >
      {children}
    </SlidesWrapper>
  )
}

const Slider = ({ children, className, params, onInit }) => {
  const { elements } = useContext(SliderContext)
  const { paginationEl, nextEl, prevEl, sliderEl, thumbsEl } = elements

  useEffect(() => {
    if (!Object.entries(elements).length) {
      return
    }

    Swiper.use([Navigation, SwiperPagination, SwiperThumbs, EffectFade])

    const thumbs = thumbsEl.current && {
      swiper: {
        el: thumbsEl.current,
        speed: 600,
        simulateTouch: false,
        allowTouchMove: false,
        loop: true,
        // БАГ.
        // Известный баг. Связан с ошибкой в вычислении ширины контейнера.
        // Без параметров `spaceBetween: 1`, `slidesPerView: "auto"`
        // и, как минимум, 3-4 изображений не работает синхронный `loop`.
        spaceBetween: 1,
        slidesPerView: "auto",
        watchSlidesVisibility: true,
      },
    }

    const slider = new Swiper(sliderEl.current, {
      speed: 600,
      simulateTouch: false,
      loop: true,
      pagination: {
        el: paginationEl.current,
        bulletClass: styles.Bullet,
        bulletActiveClass: "_active",
        clickable: true,
      },
      navigation: {
        nextEl: nextEl.current,
        prevEl: prevEl.current,
      },
      thumbs,
      ...params,
    })

    // eslint-disable-next-line no-unused-expressions
    onInit && onInit(slider)
  }, [elements])

  return <div className={className}>{children}</div>
}

export const SliderWrapper = ({ children, ...props }) => {
  const [state, setEl] = useSetState({
    paginationEl: {},
    nextEl: {},
    prevEl: {},
    sliderEl: {},
    thumbsEl: {},
  })

  return (
    <SliderContext.Provider value={{ setEl, elements: state }}>
      <Slider {...props}>{children}</Slider>
    </SliderContext.Provider>
  )
}
