import React from "react"

import { Border } from "~/components/Helpers/Border/Border"
import { Arrow, SliderWrapper, Slides } from "~/components/SliderWrapper/SliderWrapper"

import img_1 from "./assets/img-1.jpg"
import img_2 from "./assets/img-2.jpg"
import img_3 from "./assets/img-3.jpg"
import img_4 from "./assets/img-4.jpg"
import img_5 from "./assets/img-5.jpg"
import img_6 from "./assets/img-6.jpg"
import img_7 from "./assets/img-7.jpg"
import img_8 from "./assets/img-8.jpg"
import styles from "./index.module.css"

const slideItems = [
  {
    img: img_1,
  },
  {
    img: img_2,
  },
  {
    img: img_3,
  },
  {
    img: img_4,
  },
  {
    img: img_5,
  },
  {
    img: img_6,
  },
  {
    img: img_7,
  },
  {
    img: img_8,
  },
]

export const Slider = () => (
  <>
    <div className={styles.Title}>Фотографии и видео с башней</div>
    <SliderWrapper
      className={styles.Wrapper}
      params={{
        slidesPerView: "auto",
        loop: false,
        simulateTouch: true,
        spaceBetween: 56,
        watchSlidesVisibility: true,
        breakpoints: {
          767: {
            spaceBetween: 40,
          },
        },
      }}
    >
      <Slides slides={slideItems} className={styles.Container} slideClassName={styles.Slide}>
        {({ img }) => (
          <div className={styles.Item}>
            <Border>
              <img src={img} />
            </Border>
          </div>
        )}
      </Slides>

      <div className={styles.Arrows}>
        <Arrow isCursorArrow direction="prev" />
        <Arrow isCursorArrow direction="next" />
      </div>
    </SliderWrapper>
  </>
)
