import { useEffect, useState } from "react"

export const useWindowScroll = () => {
  if (typeof window !== "object") {
    return { scrollX: undefined, scrollY: undefined }
  }

  const getScroll = () => ({
    scrollX: window.scrollX,
    scrollY: window.scrollY,
  })

  const [scroll, setScroll] = useState(getScroll())

  useEffect(() => {
    const onScroll = () => {
      setScroll(getScroll())
    }

    window.addEventListener("scroll", onScroll, false)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return scroll
}
