import React from "react"
import { useInView } from "react-intersection-observer"

import { Title } from "../Helpers/Title/Title"
import { Audio } from "./components/Audio/Audio"
import { Slider } from "./components/Slider/Slider"
import styles from "./index.module.css"

const THRESHOLD = [
  0,
  0.1,
  0.2,
  0.3,
  0.4,
  0.5,
  0.6,
  0.7,
  0.8,
  0.9,
  1,
  0.9,
  0.8,
  0.7,
  0.6,
  0.5,
  0.4,
  0.3,
  0.2,
  0.1,
]
export const Voice = () => {
  const [ref, inView, entry] = useInView({
    rootMargin: "200px 0px 0px 0px",
    threshold: THRESHOLD,
  })

  return (
    <div ref={ref} className={styles.Wrapper} id="voices">
      <div className="container grid grid--col-12">
        <div className={styles.Hero}>
          <Title as="h2" type="text" className={styles.Title}>
            Голос народа
          </Title>

          <div className={styles.Subtitle}>
            Все реагировали на&nbsp;снос по-разному, кто-то даже выходил на&nbsp;защиту
            и&nbsp;обнимал башню, а&nbsp;кто-то одобрял проект. Однозначно это событие затронуло
            всех горожан.
          </div>
        </div>

        <Audio inView={inView} volume={inView ? entry.intersectionRatio : 0} />

        <div className={styles.Slider}>
          <Slider />
        </div>
      </div>
    </div>
  )
}
