// TODO: https://css-tricks.com/using-requestanimationframe-with-react-hooks/
// Переделать компонент, чтобы не перерисовывать DOM каждый раз при
// изменении state `isMouseDown`.

import anime from "animejs/lib/anime.es.js"
import React, { useEffect, useRef, useState } from "react"

import styles from "./index.module.css"

export const ButtonMobile = ({ onClick, title }) => {
  const svg = useRef(null)
  const [isMouseDown, setMouseDown] = useState(false)

  useEffect(() => {
    const morphConst = {
      polygon: [
        [
          "4.5 4.5 4.5 4.5 4.5 70.26 146.36 70.26 288.23 70.26 288.23 4.5 4.5 4.5",
          "0.5 0.5 0.5 0.5 0.5 66.26 142.36 66.26 284.23 66.26 284.23 0.5 0.5 0.5",
        ],
        [
          "4.5 70.26 4.5 70.26 9.76 75.5 9.76 75.5 293.49 75.5 288.23 70.26 4.5 70.26",
          "142.36 66.26 0.5 66.26 9.77 75.5 151.64 75.5 293.5 75.5 284.23 66.26 142.36 66.26",
        ],
        [
          "291.6 7.86 288.23 4.5 288.23 70.26 290.86 72.88 293.49 75.5 293.5 9.75 291.6 7.86",
          "288.86 5.12 284.23 0.5 284.23 66.26 288.86 70.88 293.5 75.5 293.5 9.74 288.86 5.12",
        ],
      ],

      text: {
        translateX: [107.34, 102.34],
        translateY: [43.67, 39.67],
      },
    }

    const btnAnime = anime.timeline({
      easing: "linear",
      duration: 200,
      autoplay: false,
      direction: isMouseDown ? "reverse" : "normal",
    })

    svg.current.childNodes.forEach((el, i) => {
      if (el.tagName === "polygon") {
        btnAnime.add(
          {
            targets: el,
            points: morphConst.polygon[i],
          },
          0,
        )
      }

      if (el.tagName === "g") {
        btnAnime.add(
          {
            targets: el,
            translateX: morphConst.text.translateX,
            translateY: morphConst.text.translateY,
          },
          0,
        )
      }
    })

    btnAnime.play()
  }, [isMouseDown])

  return (
    <div
      className={styles.Wrapper}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => {
        setMouseDown(false)
        onClick()
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="294"
        height="76"
        viewBox="0 0 294 76"
        ref={svg}
      >
        <polygon
          className={styles.PolygonTop}
          points="0.5 0.5 0.5 0.5 0.5 66.26 142.36 66.26 284.23 66.26 284.23 0.5 0.5 0.5"
        />
        <polygon points="142.36 66.26 0.5 66.26 9.77 75.5 151.64 75.5 293.5 75.5 284.23 66.26 142.36 66.26" />
        <polygon points="288.86 5.12 284.23 0.5 284.23 66.26 288.86 70.88 293.5 75.5 293.5 9.74 288.86 5.12" />
        <g transform="translate(48.34 43.67)">
          <text>{title}</text>
        </g>
      </svg>
    </div>
  )
}
