import anime from "animejs/lib/anime.es.js"
import React, { useState } from "react"
import Headroom from "react-headroom"

import { Link } from "../Helpers/Link/Link"
import { SoundButton } from "../SoundButton/SoundButton"
import { useZoomContext } from "../ZoomProvider/ZoomProvider"
import imgButtonMenuClose from "./assets/button-menu-close.svg"
import imgButtonMenuOpen from "./assets/button-menu-open.svg"
import imgLogo from "./assets/logo.svg"
import { Dropdown } from "./components/Dropdown/Dropdown"
import { Share } from "./components/Share/Share"
import styles from "./index.module.css"

export const Header = () => {
  const [isOpened, setDropdown] = useState(false)
  const [windowScrollY, setWindowScrollY] = useState(0)
  const { zoom } = useZoomContext()

  const toggleDropdown = () => {
    setDropdown(!isOpened)

    if (!isOpened) setWindowScrollY(window.scrollY)

    document.body.dataset.state = isOpened ? "" : "no-scroll"
    document.documentElement.dataset.state = isOpened ? "" : "no-scroll"

    if (isOpened) window.scroll({ top: windowScrollY })
  }

  const scrollTo = (e) => {
    const scrollElement = document.scrollingElement || document.body || document.documentElement
    const id = e.currentTarget.getAttribute("href")
    const el = document.querySelector(id)
    const elOffsetTop = el.offsetTop
    e.preventDefault()

    anime({
      targets: scrollElement,
      scrollTop: elOffsetTop * zoom,
      duration: 1000,
      easing: "easeInOutExpo",
    })
  }

  return (
    <Headroom>
      <div className={styles.Wrapper} data-state={"visible"}>
        <div className="container">
          <div className={styles.Inner}>
            <div className={styles.Left}>
              <div className={styles.ButtonMenu} onClick={toggleDropdown}>
                <img src={isOpened ? imgButtonMenuClose : imgButtonMenuOpen} />
              </div>

              <div className={styles.Links}>
                {/* <Link type="white" href="#!" className={styles.Link}>
                  О проекте
                </Link> */}

                <Link type="red" href="#mobile-app" className={styles.Link} onClick={scrollTo}>
                  Построй башню
                </Link>
              </div>
            </div>

            <div className={styles.Right}>
              <div className={styles.Share}>
                <Share />
              </div>

              <SoundButton />

              <a
                href="https://beaversbrothers.ru/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.Logo}
              >
                <img src={imgLogo} />
              </a>
            </div>
          </div>
        </div>

        {isOpened && <Dropdown toggleDropdown={toggleDropdown} scrollTo={scrollTo} />}
      </div>
    </Headroom>
  )
}
