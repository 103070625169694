import React from "react"

import { Footer } from "~/components/Footer/Footer"
import { Future } from "~/components/Future/Future"
import { Header } from "~/components/Header/Header"
import { MobileApp } from "~/components/MobileApp/MobileApp"
// import { Postcard } from "~/components/Postcard/Postcard"
import { TowerWrapper } from "~/components/TowerWrapper/TowerWrapper"
import { Voice } from "~/components/Voice/Voice"
import useIsTablet from "~/hooks/useIsTablet"

import { Preloader } from "../Preloader/Preloader"
import { SoundProvider } from "../SoundProvider/SoundProvider"
import { ZoomProvider } from "../ZoomProvider/ZoomProvider"

export const App = () => {
  const isTablet = useIsTablet()
  return (
    <ZoomProvider>
      <SoundProvider>
        {window.innerWidth > 763 && <Preloader />}
        <Header />
        <TowerWrapper />
        <Voice />
        <Future />
        {/* <Postcard /> */}
        {isTablet && <MobileApp />}
        <Footer />
      </SoundProvider>
    </ZoomProvider>
  )
}
