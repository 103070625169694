import * as THREE from "three"
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import DAT from "three/examples/jsm/libs/dat.gui.module"
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"

let container, scene, renderer, camera, light, cameraPov, cameraPos

const onScroll = (val) => {
  const k = 2.65
  cameraPov.position.y = 23500 - val * k
  cameraPos.position.y = 24000 - val * k

  cameraPos.rotation.y = Math.PI / 2 + Math.PI * (val / 10000)

  let z = 5000 + val * 6
  if (z > 10000) {
    z = 10000
  }
  camera.position.z = -z
}

const onResize = () => {
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()

  renderer.setSize(
    window.innerWidth / parseFloat(document.body.style.zoom || "1"),
    window.innerHeight / parseFloat(document.body.style.zoom || "1"),
  )
}

const init = () => {
  container = document.getElementById("gl-back")

  camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 1, 50000)

  scene = new THREE.Scene()

  light = new THREE.HemisphereLight(0xffffff, 0x666666)
  light.position.set(0, 500, 0)
  scene.add(light)

  light = new THREE.DirectionalLight(0xffffff)
  light.position.set(0, 500, 500)
  scene.add(light)

  cameraPov = new THREE.Mesh(
    new THREE.BoxGeometry(10, 10, 10),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
  )
  cameraPov.position.set(0, 24000, 0)
  scene.add(cameraPov)

  cameraPos = new THREE.Mesh(
    new THREE.BoxGeometry(10, 10, 10),
    new THREE.MeshBasicMaterial({ color: 0x000000 }),
  )

  cameraPos.position.set(0, 23800, 0)
  scene.add(cameraPos)
  cameraPos.add(camera)

  // boom = new THREE.Mesh(
  //   new THREE.PlaneGeometry(40, 30),
  //   new THREE.MeshBasicMaterial({ color: 0xffffff }),
  // )
  // boom.position.z = -100
  // camera.add(boom)

  const textureLoader = new THREE.TextureLoader()

  const textures = {
    textureTopMap: "./assets/textures/Tower_V2_UV_Tower_top_AlbedoTransparency.png",
    textureTopNormal: "./assets/textures/Tower_V2_UV_Tower_top_Normal.png",
    textureTower1: "./assets/textures/Tower_V2_UV_Tower_1_AlbedoTransparency.png",
    textureTower1Normal: "./assets/textures/Tower_V2_UV_Tower_1_Normal.png",
    textureTower2: "./assets/textures/Tower_V2_UV_Tower_2_and_metal_AlbedoTransparency.png",
    textureTower2Normal: "./assets/textures/Tower_V2_UV_Tower_2_and_metal_Normal.png",
    textureTower3: "./assets/textures/Tower_V2_UV_Tower_3_AlbedoTransparency.png",
    textureTower3Normal: "./assets/textures/Tower_V2_UV_Tower_3_Normal.png",
    textureTower4: "./assets/textures/Tower_V2_UV_Tower_4_AlbedoTransparency.png",
    textureTower4Normal: "./assets/textures/Tower_V2_UV_Tower_4_Normal.png",
    textureTower5: "./assets/textures/Tower_V2_UV_Tower_5_AlbedoTransparency.png",
    textureTower5Normal: "./assets/textures/Tower_V2_UV_Tower_5_Normal.png",
    textureTowerInside: "./assets/textures/Tower_V2_UV_Tower_Inside_AlbedoTransparency.png",
    textureTowerInsideNormal: "./assets/textures/Tower_V2_UV_Tower_Inside_Normal.png",
    textureConstruct: "./assets/textures/Tower_V2_UV_Metal_Construct_AlbedoTransparency.png",
    textureConstructNormal: "./assets/textures/Tower_V2_UV_Metal_Construct_Normal.png",
    texture1stFloor: "./assets/textures/Tower_V2_UV_1st Floor_AlbedoTransparency.png",
    texture1stFloorNormal: "./assets/textures/Tower_V2_UV_1st Floor_Normal.png",
    textureCup1: "./assets/textures/Tower_V2_UV_Cup_1_AlbedoTransparency.png",
    textureCup1Normal: "./assets/textures/Tower_V2_UV_Cup_1_Normal.png",
    textureBrickCup: "./assets/textures/Tower_V2_UV_Cup_and_Bricks_AlbedoTransparency.png",
    textureBrickCupNormal: "./assets/textures/Tower_V2_UV_Cup_and_Bricks_Normal.png",
    textureParapet: "./assets/textures/Tower_V2_UV_Parapet_AlbedoTransparency.png",
    textureParapetNormal: "./assets/textures/Tower_V2_UV_Parapet_Normal.png",
    textureWindows: "./assets/textures/Tower_V2_UV_Matal_part_Window_AlbedoTransparency.png",
    textureWindowsNormal: "./assets/textures/Tower_V2_UV_Matal_part_Window_Normal.png",
    textureWingDown: "./assets/textures/Tower_V2_UV_Wing_down_AlbedoTransparency.png",
    textureWingDownNormal: "./assets/textures/Tower_V2_UV_Wing_down_Normal.png",
    // textureBoom: "./assets/textures/boom.png",
  }

  const entries = Object.entries(textures)
  for (const [v, t] of entries) {
    textures[v] = textureLoader.load(t)
  }
  window.dispatchEvent(new CustomEvent("glProgress", { detail: { textures: true } }))

  // boom.material.map = textures.textureBoom
  // boom.material.alphaTest = 0.5

  // model
  const loader = new FBXLoader()
  loader.load("./assets/tower.fbx", (object) => {
    object.traverse(function(child) {
      if (child.isMesh) {
        child.castShadow = true
        child.receiveShadow = true

        child.material = Array.isArray(child.material) ? child.material : [child.material]

        child.material = child.material.map((mat) => {
          //   console.log(mat.name)
          if (mat.name === "Tower_Top") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureTopMap
            mat.normalMap = textures.textureTopNormal
            mat.roughness = 0.75
          }

          if (mat.name === "Tower_1") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureTower1
            mat.normalMap = textures.textureTower1Normal
            mat.roughness = 0.75
          }
          if (mat.name === "Tower_2") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureTower2
            mat.normalMap = textures.textureTower2Normal
            mat.roughness = 0.75
          }
          if (mat.name === "Tower_3") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureTower3
            mat.normalMap = textures.textureTower3Normal
            mat.roughness = 0.75
          }
          if (mat.name === "Tower_4") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureTower4
            mat.normalMap = textures.textureTower4Normal
            mat.roughness = 0.75
          }
          if (mat.name === "Tower_5") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureTower5
            mat.normalMap = textures.textureTower5Normal
            mat.roughness = 0.75
          }
          if (mat.name === "Tower_Inside") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureTowerInside
            mat.normalMap = textures.textureTowerInsideNormal
            mat.roughness = 0.75
          }
          if (mat.name === "Metal_Construct") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureConstruct
            mat.normalMap = textures.textureConstructNormal
            mat.alphaTest = 0.33
            mat.roughness = 0.75
            mat.side = THREE.DoubleSide
          }
          if (mat.name === "1st_Floor") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.texture1stFloor
            mat.normalMap = textures.texture1stFloorNormal
            mat.roughness = 0.75
          }
          if (mat.name === "Cup") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureCup1
            mat.normalMap = textures.textureCup1Normal
            mat.roughness = 0.75
          }
          if (mat.name === "Brick_Cup") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureBrickCup
            mat.normalMap = textures.textureBrickCupNormal
            mat.roughness = 0.75
          }
          if (mat.name === "Windows") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureWindows
            mat.normalMap = textures.textureWindowsNormal
            mat.roughness = 0.75
          }
          if (mat.name === "Wing_Down") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureWingDown
            mat.normalMap = textures.textureWingDownNormal
            mat.roughness = 0.75
            mat.side = THREE.DoubleSide
          }
          if (mat.name === "Parapet") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.map = textures.textureParapet
            mat.normalMap = textures.textureParapetNormal
            mat.alphaTest = 0.33
            mat.roughness = 0.75
            mat.side = THREE.DoubleSide
          }
          if (mat.name === "Bottom") {
            mat = new THREE.MeshStandardMaterial(mat.material)
            mat.color.setHex(0x515354)
            mat.side = THREE.DoubleSide
          }
          return mat
        })
      }
    })
    scene.add(object)
    window.dispatchEvent(new CustomEvent("glProgress", { detail: { tower: true } }))
  })

  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(window.innerWidth, window.innerHeight)
  renderer.shadowMap.enabled = true
  container.appendChild(renderer.domElement)

  //   const controls = new OrbitControls(camera, renderer.domElement)
  //   controls.target.set(0, 0, 0)
  //   controls.update()

  window.addEventListener(
    "resize",
    () => {
      onResize()
    },
    false,
  )

  window.addEventListener(
    "scroll",
    () => {
      onScroll(window.scrollY)
    },
    false,
  )

  onResize()
  onScroll(0)
}

const render = () => {
  requestAnimationFrame(render)

  camera.lookAt(cameraPov.position)

  // cameraPos.rotation.y += Math.PI / 2048

  renderer.render(scene, camera)
}

const datgui = () => {
  if (location.hostname !== "localhost") {
    return
  }

  const gui = new DAT.GUI()
  gui.open()
}

window.onload = () => {
  if (window.innerWidth <= 768) {
    window.dispatchEvent(new CustomEvent("glProgress", { detail: { tower: true } }))
    return
  }

  init()
  datgui()
  render()
}
