import { useCallback, useState } from "react"

export const useSetState = (key, initialState) => {
  const [state, setState] = useState(key, initialState)

  const setMergeState = useCallback(
    (newState) => setState((oldState) => ({ ...oldState, ...newState })),
    [setState],
  )

  return [state, setMergeState]
}
