import React, { useEffect, useRef } from "react"

import { Title } from "../Helpers/Title/Title"
import imgAppStore from "./assets/logo-app-store.svg"
import imgPlayMarket from "./assets/logo-play-market.svg"
import imgPhone from "./assets/phone.png"
import styles from "./index.module.css"

export const MobileApp = () => {
  const marquee = useRef(null)
  const animationTime = 15000

  useEffect(() => {
    let start = performance.now()

    requestAnimationFrame(function step(now) {
      let progress = (now - start) / animationTime

      if (progress >= 1) {
        progress = 0
        start = now
      }

      if (!marquee.current) return

      marquee.current.style.transform = `translateX(-${(100 * progress) / 2}%)`
      requestAnimationFrame(step)
    })
  }, [])

  return (
    <div className={styles.Wrapper} id="mobile-app">
      <div className="container grid grid--col-12">
        <div className={styles.Hero}>
          <Title as="h2" type="text" className={styles.Title}>
            Bashnya&nbsp;AR
          </Title>

          <div>
            Посмотри, какой башня могла&nbsp;бы стать, если&nbsp;бы ее&nbsp;достроили. Поднимись
            на&nbsp;самый верх. Посмотри изнутри. Размести ее&nbsp;в&nbsp;городе. Представь, что
            взрыва никогда не&nbsp;было.
          </div>

          <div className={styles.Stores}>
            <a
              href={`https://play.google.com/store/apps/details?id=ru.dabb.bashnyaar`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.StoresLink}
            >
              <img src={imgPlayMarket} />
            </a>
            <a
              href={`https://apps.apple.com/us/app/bashnya-ar/id1485237320?l=ru&ls=1`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.StoresLink}
            >
              <img src={imgAppStore} />
            </a>
          </div>
        </div>

        <div className={styles.Picture}>
          <img src={imgPhone} />
        </div>

        <div className={styles.InfiniteLine} ref={marquee}>
          <Title type="text" className={styles.InfiniteText}>
            Впиши башню в&nbsp;городское пространство&nbsp;
          </Title>
          <Title type="text" className={styles.InfiniteText}>
            Впиши башню в&nbsp;городское пространство
          </Title>
        </div>
      </div>
    </div>
  )
}
