import React from "react"

import styles from "./index.module.css"

export const Caption = ({ caption }) => (
  <div className={styles.Wrapper}>
    <div className={styles.Inner}>
      <div>
        <div className={styles.Title} dangerouslySetInnerHTML={{ __html: caption.title }} />
        <div
          className={styles.Description}
          dangerouslySetInnerHTML={{ __html: caption.description }}
        />
      </div>
    </div>
  </div>
)
